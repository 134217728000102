.btn {
  box-shadow: 0px 6px 75px rgba(100, 87, 87, 0.05);
  border-radius: 12px;
  font-weight: 500;
  font-size: 16px;
  height: 55px;

  &--black {
    background: var(--main-blue);
    color: #fff;
  }

  &--orange {
    background: #ff5900;
    color: #fff;
    border: 0;
  }

  &--bordered {
    color: rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.5);
    background: #fff;
  }
}

.btn-base {
  display: block;
  position: relative;
  border-radius: 12px;
  height: 55px;
  font-size: 16px;
  line-height: 48px;
  color: #fff !important;
  text-decoration: none !important;
  background: var(--main-blue);
  appearance: none;
  border: none;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.3s ease, color 0.3s ease;

  &:focus {
    outline: none !important;
  }

  &[disabled] {
    opacity: 0.3;
    cursor: default;
    pointer-events: none;
  }

  &.state-bordered {
    background: none;
    height: 48px;
    line-height: 46px;
    border: 1px solid var(--main-blue);
    color: var(--main-blue) !important;
  }

  &.full-width {
    width: 100%;
  }
}

.link-base {
  color: var(--main-blue) !important;
  text-decoration: none !important;
  border-bottom: 1px solid #000;
  line-height: 1;
  transition: all 0.3s ease;
  font-size: 14px !important;
}

.btn-inline {
  @extend .link-base;

  border-width: 0 0 1px 0;
  height: auto;
  background: none;
  padding: 0;

  &:focus {
    outline: none;
  }
}
