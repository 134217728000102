html {
  font-size: 10px;
  min-width: 320px;

  @include mobile {
    font-size: calc(10px + 13 * (100vw - 320px) / 447);
  }
}

body {
  color: var(--ion-text-color);
  font-family: var(--ion-font-family);
  font-size: var(--ion-text-size);
  background-color: var(--ion-background-color);
}

::selection {
  background: #000;
  color: #fff;
}

.ion-page {
  max-width: var(--containerWidthBigMobile);
  margin: 0 auto;
  // padding-bottom: var(--ion-safe-area-bottom);
}

iframe.with-appled {
  padding-top: 59px;
  background-color: #fff;
}

img[src*='/assets/images/client-menu/empty-dish.png'] {
  transform: none !important;
}

picture:has(source.error) {
  img {
    transform: none !important;
  }
}
