@import 'core/utils';

.text-10 {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
}

.text-12 {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.text-14 {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.text-16 {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.text-18 {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

.text-20 {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

.text-22 {
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
}

.text-26 {
  font-size: 26px;
  font-weight: 400;
  line-height: 30px;
}

.text-thin {
  font-weight: 300;
}

.text-normal {
  font-weight: 400;
}

.text-semibold {
  font-weight: 500;
}

.text-bold {
  font-weight: 600;
}

.text-bolder {
  font-weight: 700;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.nowrap {
  white-space: nowrap;
}
