@use 'sass:map';

// media queries
@mixin small-mobile {
  @media screen and (max-width: 375px) {
    @content;
  }
}

@mixin medium-mobile {
  @media screen and (max-width: 414px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 767px) {
    @content;
  }
}

$spacer: 5px;
$spacers: (
  0: 0,
  1: $spacer * 1,
  2: $spacer * 2,
  3: $spacer * 3,
  4: $spacer * 4,
  5: $spacer * 5,
  6: $spacer * 6,
  7: $spacer * 7,
  8: $spacer * 8,
  9: $spacer * 9,
  10: $spacer * 10,
);
$sides: (top, bottom, left, right);

@each $space-index,
  $space-value
    in map.merge(
      $spacers,
      (
        'auto': 'auto',
      )
    )
{
  .m {
    &-#{$space-index} {
      margin: #{$space-value} !important;
    }

    &y-#{$space-index} {
      margin-top: #{$space-value} !important;
      margin-bottom: #{$space-value} !important;
    }
    &x-#{$space-index} {
      margin-left: #{$space-value} !important;
      margin-right: #{$space-value} !important;
    }

    @each $side in $sides {
      &#{str-slice($side, 0, 1)}-#{$space-index} {
        margin-#{$side}: #{$space-value} !important;
      }
    }
  }
}

@each $space-index, $space-value in $spacers {
  .p {
    &-#{$space-index} {
      padding: #{$space-value} !important;
    }

    &y-#{$space-index} {
      padding-top: #{$space-value} !important;
      padding-bottom: #{$space-value} !important;
    }
    &x-#{$space-index} {
      padding-left: #{$space-value} !important;
      padding-right: #{$space-value} !important;
    }

    @each $side in $sides {
      &#{str-slice($side, 0, 1)}-#{$space-index} {
        padding-#{$side}: #{$space-value} !important;
      }
    }
  }

  .gap {
    &-#{$space-index} {
      gap: #{$space-value} !important;
    }
  }
}
