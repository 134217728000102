@font-face {
  font-family: 'SFPro';
  font-weight: 700;
  font-style: normal;
  src: url('/assets/fonts/sf/SFProDisplay-Bold.woff2') format('woff2'), url('/assets/fonts/sf/SFProDisplay-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'SFPro';
  font-weight: 600;
  font-style: normal;
  src: url('/assets/fonts/sf/SFProDisplay-Semibold.woff2') format('woff2'),
    url('/assets/fonts/sf/SFProDisplay-Semibold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'SFPro';
  font-weight: 500;
  font-style: normal;
  src: url('/assets/fonts/sf/SFProDisplay-Medium.woff2') format('woff2'), url('/assets/fonts/sf/SFProDisplay-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'SFPro';
  font-weight: 400;
  font-style: normal;
  src: url('/assets/fonts/sf/SFProDisplay-Regular.woff2') format('woff2'), url('/assets/fonts/sf/SFProDisplay-Regular.woff') format('woff');
  font-display: swap;
}

// Navigo

@font-face {
  font-weight: 500;
  font-family: Navigo;
  font-style: normal;
  src: url('/assets/fonts/Navigo/Navigo-Medium.woff2') format('woff2'), url('/assets/fonts/Navigo/Navigo-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-weight: 300;
  font-family: Navigo;
  font-style: normal;
  src: url('/assets/fonts/Navigo/Navigo-Light.woff2') format('woff2'), url('/assets/fonts/Navigo/Navigo-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-weight: bold;
  font-family: Navigo;
  font-style: normal;
  src: url('/assets/fonts/Navigo/Navigo-Bold.woff2') format('woff2'), url('/assets/fonts/Navigo/Navigo-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-weight: normal;
  font-family: Navigo;
  font-style: normal;
  src: url('/assets/fonts/Navigo/Navigo-Regular.woff2') format('woff2'), url('/assets/fonts/Navigo/Navigo-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-weight: 200;
  font-family: Navigo;
  font-style: normal;
  src: url('/assets/fonts/Navigo/Navigo-ExtraLight.woff2') format('woff2'),
    url('/assets/fonts/Navigo/Navigo-ExtraLight.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-weight: 900;
  font-family: Navigo;
  font-style: normal;
  src: url('/assets/fonts/Navigo/Navigo-Black.woff2') format('woff2'), url('/assets/fonts/Navigo/Navigo-Black.woff') format('woff');
  font-display: swap;
}
